import React from "react"
import { Button } from '../../../framework/controls'
import { downloadPdf } from '../../../framework/utils/pdf' 
import { sort, moment } from '../../../framework/utils/helper'
import { Excel } from "../../../framework/utils"

import { EarningType, RemittanceDetail }  from '../../../entities'
import { ConfigService, EmployerService } from '../../../services'
import InvoiceOrStatementPdf from "../reporting/InvoiceOrStatementPDF"


export default class ReportsMenu extends React.Component {
    constructor(props){
        super(props)
        this.state = {show: ''}
    }
    
    render() {
        const { isDirty } = this.props
        return (<div className='v-center'>
            <Button disabled={isDirty} key="downloads" className='btn-secondary px-3' onClick={this.swap.bind(this)}>Downloads...</Button> 
            <div className={this.state.show + 'dropdown-menu'} onMouseLeave={this.handleMouseOut.bind(this)}>
                <div className="dropdown-item" onClick={this.handleDownloadTemplate.bind(this)} >Earning's Template</div>
                <div className="dropdown-item" onClick={this.handleContributionsReport.bind(this)} >Contribution's Report</div>
                {this.props.remittance.period.yearEnd && <div className="dropdown-item" onClick={this.handleYearlyContributionsReport.bind(this)}>Yearly Contribution's Report</div>}
                <div className="dropdown-item" onClick={this.handleEarningsReport.bind(this)} >Earning's Report</div>
                <div className="dropdown-item" onClick={this.handleStatementDownload.bind(this)} >Monthly Statement</div>
            </div>
        </div>)
    }
    
    swap() {this.setState({show: this.state.show ? '' : 'show '})}
    handleMouseOut(e) {this.setState({show: ""})}
    
    handleDownloadTemplate(e) {
        const { remittance, earningTypes } = this.props
        const columnsMap = ReportsMenu.getEarningsExcelHeaders(earningTypes)
        delete columnsMap.warning
        const excel = new Excel('Earnings_Template')
        excel.addSheet(columnsMap, remittance.details.map(det => ({employment: det.employment, employmentPpStatusDesc: det.employmentPpStatusDesc})), 'Earnings Template')
        excel.addPrintedTime('Earnings Template')
        excel.download()
        this.setState({show: ''})
    }
    
    handleEarningsReport(e) {
        const { remittance, earningTypes } = this.props
        const columnsMap = ReportsMenu.getEarningsExcelHeaders(earningTypes)
        const excel = new Excel('Earnings_Details_' + remittance.period.longDesc)
        excel.addSheet(columnsMap, remittance.details)
        excel.download()
        this.setState({show: ''})
    }
    
    handleContributionsReport(e) {
        const { remittance } = this.props
        const excel = new Excel('Contribution_details_' + remittance.period.longDesc)
        excel.addSheet(getContributionHeaders(), sort(remittance.details.all,'employment.person.name'), 'Contribution Details')
        excel.addPrintedTime('Contribution Details')
        excel.download()
        this.setState({show: ''})
    }

    handleYearlyContributionsReport() {
        const { remittance, employer } = this.props
        const selectedYear = remittance.period.year
        const remittanceByYear = employer.remittances.filter(rem => rem.period.year === selectedYear)
        const excel = new Excel(`${selectedYear} Contribution Report`)
        remittanceByYear.forEach(rem => { 
            excel.addSheet(getContributionHeaders(), sort(rem.details.all,'employment.person.name'), rem.period.longDesc)
            excel.addPrintedTime(rem.period.longDesc)
        })
        excel.download()
    }
    
    async handleStatementDownload() {
        const { remittance, employer } = this.props
        return Promise.all([
            ConfigService.getConfig('bank'),
            ConfigService.getConfig('corporate')
        ]).then(([bank, corporate]) => {
            return downloadPdf(
                <InvoiceOrStatementPdf
                    config={{ bank, corporate }}
                    employer={employer}
                    remittance={remittance}
                    isStatement
                />
            );
        })
    }
    
}

function getContributionHeaders() {  
    const headers = new Excel.Headers(RemittanceDetail, ['employment.person.sin', 'employment.noEmp', 'employment.person.name','employmentPpStatusDesc', 'employmentStatusEffDate', 'contributions.reg', 'contributions.mat', "contributions.ltd", "contributions.slf", "contributions.vol", "adjustmentContributions.total", "totalEmployeeContributions", "message.desc", "formalEmployersTasksDesc", "cmt"])
    headers['adjustmentContributions.total'].title = 'Adjustments'
    headers['message.desc'].title = 'EMPLOYER ACTIONS REQUIRED';
    headers['formalEmployersTasksDesc'].title = 'ADMIN ONLY - Outstanding Tasks {TEAM TO HIDE before sending}'
    const propsToSum = ['contributions.reg', 'contributions.mat', "contributions.ltd", "contributions.slf", "contributions.vol", "adjustmentContributions.total", "totalEmployeeContributions"]
    propsToSum.forEach(title => headers[title].topCalc = true)
    return headers
}


ReportsMenu.getEarningsExcelHeaders = function getEarningsExcelHeaders(earningTypes) {
    const groupHeaders = new Excel.Headers(RemittanceDetail, ['emp', 'sum', 'REG', 'OVR', 'OTE', 'NPE', 'warning', 'cmt'])
    groupHeaders['emp'].convertToGroupHeader(new Excel.Headers(RemittanceDetail, 'employment.person.sin, employment.noEmp, employment.person.name, employmentPpStatusDesc'), 'EMPLOYEE')
    groupHeaders['sum'].convertToGroupHeader(new Excel.Headers(RemittanceDetail, 'earnings.pensionable, ytpEarnings.pensionable'), 'SUMMARY')
    groupHeaders['sum'].headers['earnings.pensionable'].title = 'Pensionable'
    groupHeaders['sum'].headers['ytpEarnings.pensionable'].title = 'YTD'
    groupHeaders['emp'].headers['employmentPpStatusDesc'].title = 'Status'
    groupHeaders['warning'].convertToGroupHeader(new Excel.Headers(RemittanceDetail, 'message.desc', { title: 'Error/Warnings'}))
    groupHeaders['warning'].title = ''
    groupHeaders['cmt'].convertToGroupHeader(new Excel.Headers(RemittanceDetail, 'cmt', { title: 'Comments'}))
    groupHeaders['cmt'].title = ''

    Object.assign(groupHeaders, ReportsMenu.getEarningTypesExcelHeaders(earningTypes))
    return groupHeaders 
}

ReportsMenu.getEarningTypesExcelHeaders = function getEarningTypesExcelHeaders(earningTypes) {
    const earningsTypesByCategory = EarningType.splitEarningsTypesByCategory(earningTypes)
    const headers = new Excel.Headers(RemittanceDetail, ['REG', 'OVR', 'OTE', 'NPE'])
    
    headers.list.forEach(header => {
        const cat = header.name
        headers[cat].title = earningsTypesByCategory[cat].text
        headers[cat].name = ''
        headers[cat].headers = new Excel.Headers(RemittanceDetail, earningsTypesByCategory[cat].earningTypes.map(type => type.code))
        earningsTypesByCategory[cat].earningTypes.forEach(type => {
            headers[cat].headers[type.code].title = type.alias || type.label
            headers[cat].headers[type.code].name = ''
            headers[cat].headers[type.code].headers = new Excel.Headers(RemittanceDetail, ['earnings.' + type.code + '.amount', 'earnings.' + type.code + '.' + type.hoursOrRate])
            headers[cat].headers[type.code].headers['earnings.' + type.code + '.amount'].title = 'Earnings'
            headers[cat].headers[type.code].topCalc = 'sum'
            if(cat === 'REG' || cat ==='OVR') headers[cat].headers[type.code].headers['earnings.' + type.code + '.' + type.hoursOrRate].title = EarningType.getOptionLabel('hoursOrRate', type.hoursOrRate)
            else delete headers[cat].headers[type.code].headers['earnings.' + type.code + '.' + type.hoursOrRate]
        })
    })
    return headers
}

