import { EmploymentService, ParticipationService } from "../../services";
import moment from "moment";
    import { EVENT_SOURCE } from "../../framework/infra/model/RefEvent";
import { Period } from "../../framework/utils";

/**
 * validation: a function that returns a boolean to determine if the action should be done
 * action: a function that adds the event to the detail
 * warnings: a function that returns a list of warnings to be displayed to the user
 */
export const EarningUploadPolicies = {
    /**
     * Add a parental leave event if the employment status is maternity and the maternity duration is over 18 weeks
     * APP-1476 (https://sysem.atlassian.net/browse/APP-1476)
     */
    parentalLeave: {
        /**
         * Checks if the action should be done - if the employment status is maternity and the maternity duration is over 18 weeks
         * @param {RemittanceDetail} detail 
         * @returns {boolean} true if the action should be done
         */
        validation: (detail) => {
            return detail.employment?.isMaternityOver18weeks();
        },
        /**
         * Performs action to add the parental leave event
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns null 
         */
        action: async (detail, options) => {
            const employment = detail.employment
            const maternityDuration = employment.employer.plan.currentRates.maternityDuration
            const parentalLeaveStart = moment(employment.eventStatuses.last.effDt).add(maternityDuration , 'days')
            const parentalLeaveEvent = { code: 'lpl',ets: parentalLeaveStart.valueOf(), source: options?.eventsSource ?? EVENT_SOURCE.FILE.key, cmt: "Approved leave after maternity. [APP]" }
            // Update the employment if the commit option is true
            if(options?.commit) {
                detail.employment.addEvent(parentalLeaveEvent, {openRemittance: options?.openRemittance});
                // Will check if we need to add a RTW event to the employments of the participation, and add it if needed
                await EmploymentService.updateEmployment(detail.employment, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }
            return null;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean} commit flag if the changes should be saved
         * @returns the warnings to show the user
         */
        warnings: async (commit) =>  commit ? ['addedParentalLeaveEvent'] : ['addParentalLeaveEvent']
    },
    /**
     * Add a `nrolFday` participation event if needed
     * Add the RTW event to the employment if needed
     * 
     * If the remittance detail's employment's participation in target period (not current period) is eligible period (participation status `ELI`),
     * and the remittance detail's earnings are not empty or the uploaded earnings are not empty,
     * and the participation doesn't already have a `nrolFday` event,
     * and the remittance detail's period is not January
     * 
     * Add first day event with guessed date (remittance's period start)
     * 
     * see APP-1141 (https://sysem.atlassian.net/browse/APP-1141)
     **/
    nrolFday: {
        /**
         * Checks if the action should be done - if the participation is eligible, there are earnings, the period isn't January, and there is no first day event
         * @param {RemittanceDetail} detail 
         * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings uploadedEarnings earnings uploaded by the user with an excel file
         * @returns {boolean} true if the action should be done
         */
        validation: (detail, uploadedEarnings) => {
            const hasUploadedEarnings = uploadedEarnings && !uploadedEarnings.earnings?.isEmpty;
            const hasEarnings = !detail.earnings?.isEmpty;
            if(!hasEarnings && !hasUploadedEarnings) {
                return false;
            }
            const hasFirstDayEvent = detail?.employment?.participation?.events?.find?.(e => e.config.isFirstDayEvent);
            const isJanPeriod = detail.period.moment.month() === Period.JAN_MONTH;
            const isEligibleInPeriod = detail.ppStatus.isEligiblePeriod();

            return !isJanPeriod && isEligibleInPeriod && !hasFirstDayEvent;
        },
        /**
         * Performs action to add the first day event
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns {ppIsNotEnrolled: boolean | undefined; hasAddedNrolPPEvent: boolean | undefined; nrolEventEffDate: number | undefined; nrolEventEmployerId: string | undefined; ppStatusCheckForRtwEvent: boolean | undefined; employmentsChecksForRtwEvent: {code: string; hasRtwEventAfterLeave: boolean; employmentIsOnLeave: boolean; hasHiredEventAfterLeave: boolean; shouldAddRtwEvent: boolean;}
         */
        action: async (detail, options) => {
            const nrolFdayEvent = { code: 'nrolFday', ets: detail.period.timestampAtPeriodStart, guessed: true, source: options?.eventsSource ?? EVENT_SOURCE.FILE.key }
            // Update the participation
            // Need to add the event here for the checksBeforeUpdate
            detail.employment.participation.addEvent(nrolFdayEvent, {openRemittance: options?.openRemittance});
            const beforeUpdateChecks = await ParticipationService.checksBeforeUpdate(detail.employment.participation)
            if(options?.commit === false) {
                // rollback the changes, remove the event
                detail.employment.participation.deleteEvent(nrolFdayEvent);
            }else{
                // Will check if we need to add a RTW event to the employments of the participation, and add it if needed
                await ParticipationService.updateParticipation(detail.employment.participation, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }
            return beforeUpdateChecks;
        },

        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean} commit flag if the changes should be saved
         * @param {RemittanceDetail} detail
         * @param {{employmentsChecksForRtwEvent: {code: string, shouldAddRtwEvent: boolean}[]}} beforeUpdateChecks this is returned by the action function
         * @returns the warnings to show the user
         */
        warnings: async (commit, detail, beforeUpdateChecks) => {
            let warnings = commit ? ['addedNrolfdayEvent'] : ['addNrolfdayEvent'];
            if(!commit){
                if(Boolean(beforeUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.shouldAddRtwEvent)){
                    warnings.push('addRtwEvent');
                }
            }else{
                const afterUpdateChecks = await ParticipationService.checksAfterUpdate(detail.employment.participation);
                if(
                    beforeUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.hasRtwEventAfterLeave === false && 
                    afterUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.hasRtwEventAfterLeave === true
                ){
                    warnings.push('addedRtwEvent');
                }
            }
            return warnings;
        }
    },

    /**
     * Add a `metElig` participation event if needed, on Jan 1st of that year, no guessed date
     * 
     * - If it's the January upload month
     * - The participation status for target period aka January (not current period) is eligible
     * - There are earnings reported in January
     * 
     * see APP-1462 (https://sysem.atlassian.net/browse/APP-1462)
     */ 
    nrolJan: {
        /**
         * Check if the action should be done - if the participation is eligible, the period is January, and there are earnings
         * @param {RemittanceDetail} detail 
         * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings earnings uploaded by the user with an excel file
         * @returns {boolean} true if the action should be done
         */
        validation: (detail, uploadedEarnings) => {
            const isJanPeriod = detail.period.moment.month() === Period.JAN_MONTH;
            const hasUploadedEarnings = uploadedEarnings && !uploadedEarnings.earnings?.isEmpty;
            const hasEarnings = detail.earnings?.pensionable;
            const isEligiblePeriod = detail.ppStatus.isEligiblePeriod();
            
            return isJanPeriod && isEligiblePeriod && (hasEarnings || hasUploadedEarnings);
        },
        /**
         * Performs the action of adding the metElig event to January 1st
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns 
         */
        action: async (detail, options) => {
            const nrolEvent = { 
                code: 'metElig', 
                ets: detail.period.timestampAtPeriodStart, 
                guessed: false, 
                source: options?.eventsSource ?? EVENT_SOURCE.FILE.key,
                cmt: `Eligible Jan 01 ${detail.period.year} as per the Eligibility Report + Earnings reported in January.  APP created NROL event as of Jan 1 [APP]`,
            }
            
            // Add NROL event to the participation
            detail.employment.participation.addEvent(nrolEvent, {openRemittance: options?.openRemittance});
            if (options?.commit === false) {
                // rollback the changes, remove the event
                detail.employment.participation.deleteEvent(nrolEvent);
            } else {
                await ParticipationService.updateParticipation(detail.employment.participation, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }

            return null;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean} commit flag if the changes should be saved
         * @returns the warnings to show the user
         */
        warnings: async (commit) =>  commit ? ['addedNrolEvent'] : ['addNrolEvent'],
    }

}